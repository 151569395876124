<template>
<a class="btn--whatsapp" href="#">
  <Icon name="ph:whatsapp-logo" class="whatsapp__icon"/>
  <p class="whatsapp__text">Vragen? Stuur dan een berichtje</p>
</a>
</template>

<style lang="scss">
.btn--whatsapp {
  display: flex;
  align-items: center;
}

.whatsapp__icon {
  height: 2.5rem;
  width: 2.5rem;
}

.whatsapp__text {
  max-width: 160px;
  text-align: left;
  margin-left: 0.75rem;
}
</style>
