<template>
<div class="nav-item" @mouseenter="openMenu" @mouseleave="closeMenu">
  <nuxt-link :to="`/${navItem.path}`" class="nav-item__link" :class="{ 'with-menu': hasChildren }" @click="emit('linked')">
    {{ navItem.title }}
    <Icon name="majesticons:chevron-down" class="chevron_down_menu" v-if="hasChildren"/>
  </nuxt-link>
  <ul class="nav-item__dropdown block--white block--elevated" v-show="menuIsOpen && hasChildren" :class="{ 'show_always': isMobile }">
    <li v-for="(child, i) in navItem.children" :key="child.id">
      <nuxt-link :to="`/${navItem.path}/${child.path}`" class="nav-dropdown__link" @click="emit('linked')">{{ child.title }}</nuxt-link>
      <div class="nav-dropdown__divider" v-if="i < navItem.children.length - 1"></div>
    </li>
  </ul>
</div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

const props = defineProps({
  navItem: {
    type: Object as PropType<object>,
    required: true
  },
  isMobile: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const emit = defineEmits(['linked']);

const menuIsOpen = ref<boolean>(false);
const hasChildren = computed(() => props.navItem.children.length > 0);

const onlyOnDesktop = (callable: Function) => () => {
  if (!props.isMobile) {
    callable();
  }
}

watchEffect(() => {
  menuIsOpen.value = props.isMobile;
})

const closeMenu = onlyOnDesktop(() => menuIsOpen.value = false);
const openMenu = onlyOnDesktop(() => menuIsOpen.value = true);
</script>

<style lang="scss">
.chevron_down_menu {
  height: 1.1rem;
  width: 1.1rem;
}

.nav-item {
  margin: $padding-sm;
  position: relative;
}

.nav-item__link {
  text-decoration: none;
  font-weight: bold;
  color: $dark_grey;
  transition: all 225ms ease-in-out;

  &:hover {
    color: $ven_red;
  }

  &:last-child {
    margin-right: 0;
  }

  &.with-menu {
    padding-bottom: 0.75rem;
  }

  .menu--fixed & {
    font-size: 1.2rem;
  }
}

.nav-item__dropdown {
  position: absolute;
  top: calc(100% + 0.75rem);
  left: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .menu--fixed & {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none;
    padding: 1rem 0;
  }
}

.nav-dropdown__link {
  padding: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  color: $dark_grey;
  transition: all 225ms ease-in-out;
  display: block;

  &:hover {
    color: $ven_red;
  }

  .menu--fixed & {
    font-size: 1rem;
  }
}

.nav-dropdown__divider {
  height: 1px;
  background: rgba($dark_grey, 0.16);
  margin: 0.5rem 0;

  .menu--fixed & {
    background: none;
  }
}
</style>
