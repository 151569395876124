<template>
<header class="header">
  <div class="header__top">
    <nuxt-link to="/">
      <Logo class="logo"/>
    </nuxt-link>
    <client-only>
      <button class="nav_menu_btn" v-if="hasMobileMenu" @click="menuActive = !menuActive">
        <Icon name="material-symbols:menu" class="menu_icon"/>
      </button>
    </client-only>
  </div>
  <nav v-show="menuActive || !hasMobileMenu" :class="{ 'menu__active': menuActive && hasMobileMenu, 'menu--fixed': hasMobileMenu }">
    <LinkNav v-for="navItem in navItems" :key="navItem.id" :nav-item="navItem" :is-mobile="hasMobileMenu" @linked="menuActive = false"/>
  </nav>
</header>
</template>

<script lang="ts" setup>
import Logo from '~/assets/svg/logo.svg?component';
import { useWindowSize } from '@vueuse/core';

const client = useStrapiClient();

const { data } = await useAsyncData(
  'navigation',
  () => client('navigation/render/main-navigation', { method: 'GET' })
);

const navItems = computed(() => {
  const nav = {};

  data.value?.forEach((navItem) => {
    if (!navItem.parent) {
      nav[navItem.id] = { ...navItem, children: [] };
    } else {
      nav[navItem.parent.id].children.push(navItem);
    }
  });

  return Object.values(nav).sort((a, b) => a.order - b.order);
});


const { width } = useWindowSize();

const hasMobileMenu = computed<boolean>(() => width.value < 896);
const menuActive = ref<boolean>(false);
</script>
