<template>
  <footer class="block block--violet block--psmall" v-if="data">
    <div class="width__maxbody footer__content">
      <div class="footer__side">
        <div class="footer__collection">
          <a :href="`mailto:${data.contact_email}`" class="text--uppercase text--larger">{{ data.contact_email }}</a>
          <a :href="`tel:${data.phone_number}`" class="text--uppercase text--larger">{{ data.phone_number }}</a>
        </div>
        
        <span class="mt-4 text--uppercase">KvK: {{ data.kvk_number }}</span>
      </div>
      <LogoWhite class="footer__logo"/>
      <div class="footer__side footer__side--right">
        <ButtonWhatsapp/>
        <div class="footer__socials">
          <a :href="data.linkedin_link" v-if="data.linkedin_link">
            <Icon name="ri:linkedin-fill" class="footer__social"/>
          </a>
          <a :href="data.facebook_link" v-if="data.facebook_link">
            <Icon name="ri:facebook-fill" class="footer__social"/>
          </a>
          <a :href="data.instagram_link" v-if="data.instagram_link">
            <Icon name="ri:instagram-line" class="footer__social"/>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import LogoWhite from '~/assets/svg/logo_white.svg?component';
import useStrapiHelper from '~~/composables/strapi';

const { find } = useStrapi();
const { flattenObject } = useStrapiHelper();

const { data } = await useAsyncData(
  'footer',
  () => find('footer'),
  {
    transform: (data) => {
      return flattenObject(data);
    }
  }
)
</script>

<style lang="scss">
.footer__content {
  display: flex;
  justify-content: space-between;

  @include breakpoint(null, $breakpoint_sm) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__side {
  width: 16rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  order: 1;

  .footer__collection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include breakpoint(null, $breakpoint_sm) {
    align-items: center;
    text-align: center;
    padding-top: 1rem;

    .footer__collection {
      align-items: center;
    }

    .mt-4 {
      margin-top: 2rem;
    }
  }
}

.footer__logo {
  max-width: 222px;
  order: 1;

  @include breakpoint(null, $breakpoint_sm) {
    margin-bottom: 2rem;
    order: 0;
  }
}

.footer__side--right {
  text-align: right;
  align-items: flex-end;

  .footer__collection {
    align-items: flex-end;
  }

  @include breakpoint(null, $breakpoint_sm) {
    align-items: center;
    text-align: center;

    margin-top: 2rem;
  }
}

footer a {
  text-decoration: none;
  color: $white;
  line-height: 1.5;
  transition: all 135ms ease-in-out;

  &:hover {
    color: $light_violet;
  }
}

.footer__socials {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;

  @include breakpoint(null, $breakpoint_sm) {
    margin-top: 2rem;
  }
}

.footer__social {
  height: 2rem;
  width: 2rem;
}
</style>
